import { Box } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { GeneGroups, L2Target } from '@resistapp/common/assays';
import { ReactNode } from 'react';
import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { AntibioticSelector } from './antibiotic-selector';
import { EnvironmentTypeSelector } from './environment-type-selector';
import { MonthSelector } from './month-selector';
import { antibioticGroupOptions, OptionBase, OptionForTargets } from './types';

type Props = {
  onChangeAntibiotic: (value: OptionBase | OptionForTargets | null) => void;
  onChangeEnvTypeGroup: (value: OptionBase['value'] | undefined) => void;
  selectedTargets: L2Target[];
  children?: ReactNode;
  envTypeSelectorOpen?: boolean;
  setEnvTypeSelectorOpen: (value: boolean) => void;
};

export function ScopeSelectors({
  onChangeAntibiotic,
  onChangeEnvTypeGroup,
  selectedTargets,
  children = null,
  envTypeSelectorOpen = false,
  setEnvTypeSelectorOpen,
}: Props) {
  const { metricMode, analyzedAntibioticGroups } = useOverviewContext();
  const { allGeneGroups } = useAssayContext();
  const selectedAntibiotic = getSelectedAntibioticOption(selectedTargets, allGeneGroups);

  return (
    <Box fontSize={theme.fontSize.medium} fontWeight={theme.fontWeight.bold} position={'relative'}>
      {children}
      <AntibioticSelector
        metricMode={metricMode}
        onChange={onChangeAntibiotic}
        selectedAntibiotic={selectedAntibiotic as OptionForTargets}
        analyzedAntibioticGroups={analyzedAntibioticGroups || []}
      />
      <EnvironmentTypeSelector
        onChange={onChangeEnvTypeGroup}
        active={envTypeSelectorOpen}
        setActive={setEnvTypeSelectorOpen}
      />
      <MonthSelector />
    </Box>
  );
}

function getSelectedAntibioticOption(selectedGroups: L2Target[], allGeneGroups: GeneGroups): OptionBase {
  // We minus one because we don't want to include 'All antibiotics' in the count
  const isAllAntibiotics = selectedGroups.length === antibioticGroupOptions.length - 1;
  const selectedValue = isAllAntibiotics ? 'All antibiotics' : selectedGroups[0];
  const groupColor = isAllAntibiotics
    ? 'white'
    : selectedGroups[0]
      ? getGroupColor(selectedGroups[0], 'antibiotic', allGeneGroups)
      : 'white';
  const groupTextColor = isAllAntibiotics
    ? theme.colors.neutral800
    : selectedGroups[0]
      ? getGroupTextColor(selectedGroups[0], 'antibiotic', allGeneGroups)
      : theme.colors.neutral800;
  return {
    value: selectedValue,
    label: selectedValue,
    textColor: groupTextColor,
    bgColor: groupColor,
  };
}
