import { PlotTooltip, usePlotTooltip } from '@resistapp/client/components/tooltips/plot-tooltip';
import { GeneAndCopyNumber } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { MetricMode } from '@resistapp/common/types';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import { useTooltipInPortal } from '@visx/tooltip';
import { isNil } from 'lodash';
import { theme } from '../shared/theme';
import { CopyNumberBar } from './copy-number-bar';
import { formatGeneMutationSuffixes } from './site-details/general-site-details';

interface Props {
  genesAndNumbers: GeneAndCopyNumber[];
  width: number;
  height: number;
  events: boolean;
  metricMode: MetricMode;
  italic?: boolean;
  activeOverviewConfiguration: OverviewChartConfiguration;
}

export function CopyNumberBarGraph({
  width,
  height,
  genesAndNumbers,
  metricMode,
  activeOverviewConfiguration,
  italic = false,
}: Props) {
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: true,
  });
  const { handleMouseMove, tooltipProps, tooltipData } = usePlotTooltip<GeneAndCopyNumber>(tooltipStuff);

  // TODO Remove reduction and 2 sided logic after we after we have confirmed that the new treated and removed bar graph is preferred for all cases
  const is2Sided = 'is2Sided' in activeOverviewConfiguration && activeOverviewConfiguration.is2Sided;
  if (metricMode === MetricMode.REDUCTION) {
    try {
      assertReductionData(genesAndNumbers, activeOverviewConfiguration);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const xMax = is2Sided ? width : width - margins.horizontal;
  const yMax = height - margins.vertical;
  const xMinScale = metricMode === MetricMode.REDUCTION ? activeOverviewConfiguration.detailBarGraphMin : 1;
  const maxNumber = activeOverviewConfiguration.detailBarGraphMax(genesAndNumbers);
  const descendingGenesAndNumbers = [...genesAndNumbers].sort((a, b) => {
    const aValue = Number(a[activeOverviewConfiguration.scaleProperty]);
    const bValue = Number(b[activeOverviewConfiguration.scaleProperty]);
    if (isNil(aValue) || isNil(bValue)) {
      return 0;
    }
    return bValue - aValue;
  });
  const rangeXMax = xMax - twoSidedBarMargin.left * (twoSidedBarMargin.rightQuantifier + 1);

  const yScale = scaleBand<string>({
    range: [0, yMax],
    round: true,
    domain: descendingGenesAndNumbers.map(d => getScaleLabel(d.assay, d.gene)),
    padding: 0.4,
  });
  const xScale = scaleLinear<number>({
    range: [0, rangeXMax],
    round: true,
    // The domain difference with is2Sided is a margin to the edge of graph to make the bars not end at the edge of the graph.
    domain: [xMinScale, maxNumber * (!is2Sided ? 1.23 : 1)],
  });

  const xBarScaleFor2Sided = scaleLinear<number>({
    range: [0, rangeXMax],
    round: true,
    domain: [0, maxNumber],
  });

  // Custom tick values for the two-sided scale
  const tickValues = undefined;

  // We want overflow:visible, since if the values in the chart go beyond min or max it's better to show the values
  return (
    <svg width={width} height={height + yMarginForxAxisLegend} overflow="visible">
      <Group top={0} left={is2Sided ? twoSidedBarMargin.left : margins.horizontal}>
        <GridColumns
          left={is2Sided ? twoSidedBarMargin.left / 2 : 0}
          height={height - 34}
          scale={xScale}
          stroke={theme.colors.neutral300}
          tickValues={tickValues}
          numTicks={6}
        />
        {!is2Sided && <Line from={{ x: 0, y: 0 }} to={{ x: 0, y: height - 34 }} stroke={theme.colors.neutral300} />}
        <AxisBottom
          left={is2Sided ? twoSidedBarMargin.left / 2 : 0}
          scale={xScale}
          top={yMax}
          tickFormat={n => activeOverviewConfiguration.formatNumber?.(n.valueOf())}
          numTicks={6}
          tickValues={tickValues}
          hideTicks
          hideAxisLine
          tickLabelProps={tickLabelProps}
        />
        {descendingGenesAndNumbers.map(geneAndCopyNumber => (
          <Group left={0} key={`bar-${geneAndCopyNumber.assay}`}>
            <CopyNumberBar
              key={`bar-${geneAndCopyNumber.assay}`}
              genesAndNumbers={descendingGenesAndNumbers}
              geneAndCopyNumber={geneAndCopyNumber}
              metricMode={metricMode}
              scaleProperty={activeOverviewConfiguration.scaleProperty}
              is2Sided={is2Sided}
              xScale={is2Sided ? xBarScaleFor2Sided : xScale}
              yScale={yScale}
              xMax={xMax}
              handleMouseMove={handleMouseMove}
              activeOverviewConfiguration={activeOverviewConfiguration}
            />
          </Group>
        ))}

        {!is2Sided && (
          <AxisLeft
            scale={yScale}
            tickFormat={getGeneFromLabel}
            tickLabelProps={{ ...tickLabelProps, fontStyle: italic ? 'italic' : 'normal' }}
            hideTicks
            hideAxisLine
          />
        )}
      </Group>
      {tooltipData ? (
        <PlotTooltip {...tooltipProps}>
          <div>
            Gene: <b>{formatGeneMutationSuffixes(tooltipData.gene)}</b>
          </div>
          <div>
            Copy nr: <b>{tooltipData.copyNumber.toFixed()}</b>
          </div>
        </PlotTooltip>
      ) : (
        ''
      )}
    </svg>
  );
}

const margins = { vertical: 40, horizontal: 140 } as const;
const yMarginForxAxisLegend = 5 as const; // This is needed to prevent the bottom axis texts getting hidden
export const twoSidedBarMargin = {
  left: 10,
  rightQuantifier: 3,
} as const;
export const BarLabelThreshold = 50 as const;
export const tickLabelProps = {
  fontSize: 14,
  color: theme.colors.neutral700,
  fontWeight: theme.fontWeight.bold,
} as const;

export function getScaleLabel(assay: string, gene: string) {
  return `${assay}#${gene}`;
}

function getGeneFromLabel(label: string) {
  return label.split('#')[1];
}

function assertReductionData(
  genesAndCopyNumbers: GeneAndCopyNumber[],
  activeOverviewConfiguration: OverviewChartConfiguration,
): asserts genesAndCopyNumbers is Array<
  GeneAndCopyNumber & { [key in keyof typeof activeOverviewConfiguration.scaleProperty]: number }
> {
  if (genesAndCopyNumbers.some(d => d[activeOverviewConfiguration.scaleProperty] === undefined)) {
    throw new Error('All items must have reduction data when in reduction mode');
  }
}
