import { antibioticL2Targets, L2Target } from '@resistapp/common/assays';
import { EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import { isNil } from 'lodash';

export type TargetOrAll = L2Target | 'All antibiotics';
export const allAntibiotics: TargetOrAll = 'All antibiotics';

export const antibioticGroupOptions: TargetOrAll[] = ['All antibiotics' as const, ...antibioticL2Targets];

export interface OptionBase {
  label: string;
  value: string;
  bgColor?: string;
  textColor?: string;
}

export interface OptionForTargets extends OptionBase {
  label: TargetOrAll;
  value: TargetOrAll;
  bgColor: string;
  textColor: string;
}

export interface OptionForEnvironmentType extends OptionBase {
  value: EnvironmentTypeGroup;
  label: string;
  isDisabled: boolean;
}

export interface BeforeOrChange {
  metric: number | null;
  // ... other properties of BeforeOrChange
}

export function isBeforeOrChangePresent(beforeOrChange: BeforeOrChange | undefined): beforeOrChange is BeforeOrChange {
  return !isNil(beforeOrChange?.metric);
}
