import { Box, SimpleGrid } from '@chakra-ui/react';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { L1Targets, L2Targets } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { RefObject, createRef, useRef } from 'react';
import { AntibioticCompositionDetails } from './site-details/antibiotic-composition-details';
import { GeneralSiteDetails } from './site-details/general-site-details';
import { MSTDetails } from './site-details/mst-details';
import { SiteDetailBarGraph } from './site-details/site-detail-bar-graph';
import { SiteDetailTreatedAndReducedBox } from './site-details/treated-and-reduced-box/site-detail-treated-and-reduced-box';

export function SiteDetailBoxes() {
  const { queryFilters } = useSampleDataContext();
  const { metricMode, selectedSiteDatum, effectiveSiteDetailsProcessMode, activeChartUnit } = useOverviewContext();
  const containerRefs = useRef<Array<RefObject<HTMLDivElement>>>(
    Array.from({ length: 6 }, () => createRef<HTMLDivElement>()),
  );

  if (!selectedSiteDatum) {
    return null;
  }

  const isPerL = activeChartUnit === ChartUnit.COPIES_PER_L;
  const perLitreMaybe = isPerL ? 'per\u00A0L' : '';

  const components = [
    metricMode === MetricMode.REDUCTION ? (
      <Box key="l2-target-stacked" gridRow="span 2" height="100%">
        <SiteDetailTreatedAndReducedBox
          containerRef={containerRefs.current[0]}
          groupingTarget={L1Targets.ARG}
          title="Antibiotic resistance gene reduction"
          explanation={`Average remaining and removed antibiotic resistance genes per L for each class of antibiotics`}
          height={640}
        />
      </Box>
    ) : (
      <AntibioticCompositionDetails
        key="antibiotic"
        containerRef={containerRefs.current[0]}
        antibioticTarget={queryFilters.filters.selectedTargets}
      />
    ),
    metricMode === MetricMode.REDUCTION ? (
      <SiteDetailTreatedAndReducedBox
        containerRef={containerRefs.current[1]}
        groupingTarget={MinorTarget.PATHOGEN}
        title="Pathogen markers"
        explanation={`Independently detected pathogen marker genes per L
            ${get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}`}
      />
    ) : (
      <SiteDetailBarGraph
        key="pathogen"
        containerRef={containerRefs.current[1]}
        target={MinorTarget.PATHOGEN}
        title="Pathogen markers"
        noGenesText="No pathogen associated genes detected"
        italic={true}
        customExplanation={({ inRawOrEffluentSamples }) => (
          <span>
            Copy numbers of independently detected pathogen marker genes {perLitreMaybe}
            {inRawOrEffluentSamples ? inRawOrEffluentSamples : ''}
          </span>
        )}
      />
    ),
    metricMode === MetricMode.REDUCTION ? (
      <SiteDetailTreatedAndReducedBox
        key="pollution"
        containerRef={containerRefs.current[2]}
        groupingTarget={MinorTarget.POLLUTION_MARKER}
        title="Pollution markers"
        explanation={`Copy numbers (per L) of genes associated with AMR pollution
          ${get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}`}
      />
    ) : (
      <SiteDetailBarGraph
        key="pollution"
        id="site-detail-box-pollution"
        containerRef={containerRefs.current[2]}
        target={MinorTarget.POLLUTION_MARKER}
        title="Pollution markers"
        titleExplanation={
          <span>
            Copy numbers {isPerL ? '(per L)' : ''} of genes associated with AMR pollution
            {get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}
          </span>
        }
        noGenesText="No pollution markers detected"
      />
    ),
    metricMode === MetricMode.REDUCTION ? (
      <SiteDetailTreatedAndReducedBox
        key="mge"
        containerRef={containerRefs.current[3]}
        groupingTarget={MinorTarget.MGE}
        title="Mobile genetic elements"
        explanation={`Copy numbers (per L) of genes associated with MGEs
          ${get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}`}
      />
    ) : (
      <GeneralSiteDetails
        key="mge"
        id="site-detail-box-mge"
        containerRef={containerRefs.current[3]}
        target={L2Targets.MGE}
        title="Mobile genetic elements"
        explanation={
          <span>
            Copy numbers {isPerL ? '(per L)' : ''} of genes associated with MGEs{' '}
            {get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}
          </span>
        }
      />
    ),
    <MSTDetails key="mst" containerRef={containerRefs.current[4]} />,
  ];

  return (
    <SimpleGrid columns={{ base: 1, lg: 2, '2xl': 3 }} gap={8} className="test-site-detail-boxes">
      {components}
    </SimpleGrid>
  );
}
