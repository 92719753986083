import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { L2Targets } from '@resistapp/common/assays';
import { friendlyCopyNumber, friendlyFoldChange } from '@resistapp/common/friendly';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { MouseEvent } from 'react';
import { theme } from '../../../shared/theme';
import { PlotTooltip, usePlotTooltip } from '../../../tooltips/plot-tooltip';
import { L2TargetTooltipContent } from './reduction-tooltip';
import { textPadding } from './treated-and-reduced-bar-graph';
import { SingleBarDatum } from './types';

interface L2TargetBarProps {
  datum: SingleBarDatum;
  barY: number;
  barHeight: number;
  beforeWidth: number;
  afterWidth: number;
  baseBarColor: string;
  changeBarColor: string;
  tooltipStuff: UseTooltipInPortal;
  hoveredTarget: L2Targets | null;
  setHoveredTarget: (target: L2Targets | null) => void;
}

function isL2Target(target: string): target is L2Targets {
  return Object.values(L2Targets).includes(target as L2Targets);
}

export function ReductionBar({
  datum,
  barY,
  barHeight,
  beforeWidth,
  afterWidth,
  baseBarColor,
  changeBarColor,
  tooltipStuff,
  hoveredTarget,
  setHoveredTarget,
}: L2TargetBarProps) {
  const { queryFilters } = useSampleDataContext();
  const { activeOverviewConfiguration } = useOverviewContext();
  const { allGeneGroups } = useAssayContext();
  const { tooltipData, tooltipProps, handleMouseMove, hideTooltip } = usePlotTooltip<SingleBarDatum>(tooltipStuff);

  const hasRealAfterData = !!datum.afterTotal;
  const isL2TargetValue = isL2Target(datum.barIdentifier);
  const isSelected = isL2TargetValue
    ? queryFilters.filters.selectedTargets.length === 0 ||
      queryFilters.filters.selectedTargets.includes(datum.barIdentifier as L2Targets)
    : true;
  const opacityModifier = isSelected ? 1 : 0.5;

  const onMouseEnter = (event: MouseEvent<SVGElement>) => {
    if (isL2TargetValue) {
      setHoveredTarget(datum.barIdentifier as L2Targets);
    }
    handleMouseMove(event, datum, true);
  };
  const onMouseLeave = () => {
    if (isL2TargetValue) {
      setHoveredTarget(null);
    }
    hideTooltip();
  };
  const onMouseMove = (event: MouseEvent<SVGElement>) => {
    handleMouseMove(event, datum);
  };
  const onClick = () => {
    if (isL2TargetValue) {
      queryFilters.toggleGeneGroupStable(datum.barIdentifier as L2Targets, true);
    }
  };

  const isHovered = isL2TargetValue && hoveredTarget === (datum.barIdentifier as L2Targets);
  return (
    <Group key={datum.barIdentifier}>
      {/* Bar for reduced (raw - treated) samples - semi-transparent */}
      <Bar
        x={0}
        y={barY}
        width={beforeWidth}
        height={barHeight}
        fill={changeBarColor}
        opacity={0.7 * opacityModifier}
        rx={3}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClick}
        style={{ cursor: isL2TargetValue ? 'pointer' : 'default' }}
      />
      {/* Bar for after (treated) samples - solid */}
      <Bar
        x={0}
        y={barY}
        width={afterWidth}
        height={barHeight}
        fill={baseBarColor}
        opacity={opacityModifier}
        rx={3}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClick}
        style={{ cursor: isL2TargetValue ? 'pointer' : 'default' }}
      />
      {/* Text label for raw (before) samples (in normal decrease case) - outside the bar */}
      <text
        x={beforeWidth + textPadding}
        y={barY + barHeight / 2}
        dy=".32em"
        fontSize={12}
        fill={theme.colors.neutral900}
        fontWeight={theme.fontWeight.bold}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClick}
        style={{
          cursor: isL2TargetValue ? 'pointer' : 'default',
          opacity: isHovered ? 1 : 0,
        }}
      >
        {friendlyCopyNumber(datum.isDecrease ? datum.beforeTotal : datum.afterTotal)}
      </text>
      {/* Text label for treated (after) samples (in normal decrease case) - inside the bar near its right edge */}
      {afterWidth > 50 && hasRealAfterData && (
        <text
          x={afterWidth - textPadding}
          y={barY + barHeight / 2}
          dy=".32em"
          dx="-.32em"
          fontSize={12}
          fill={theme.colors.neutral100}
          fontWeight={theme.fontWeight.bold}
          textAnchor="end"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onClick={onClick}
          style={{ cursor: isL2TargetValue ? 'pointer' : 'default' }}
        >
          {friendlyCopyNumber(datum.isDecrease ? datum.afterTotal : datum.beforeTotal)}
        </text>
      )}
      {/* Reduction lalel in the middle of the difference area */}
      {Math.abs((beforeWidth || 0) - (afterWidth || 0)) > 30 && ( // Show if the before bar is wide enough
        <text
          x={
            hasRealAfterData
              ? ((afterWidth || 0) + beforeWidth) / 2 // Center between the two bars if we have after data
              : beforeWidth / 2 // Center in the before bar if no after data
          }
          y={barY + barHeight / 2}
          dy=".32em"
          fontSize={12}
          fill={activeOverviewConfiguration.getFriendlyTextColor(-1)}
          fontWeight={theme.fontWeight.bold}
          textAnchor="middle"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onClick={onClick}
          style={{ cursor: isL2TargetValue ? 'pointer' : 'default' }}
        >
          {friendlyFoldChange(datum.log10Reduction)}
        </text>
      )}
      {tooltipData && (
        <PlotTooltip {...tooltipProps} backgroundColor={theme.colors.blue50}>
          <L2TargetTooltipContent
            tooltipData={tooltipData}
            activeOverviewConfiguration={activeOverviewConfiguration}
            allGeneGroups={allGeneGroups}
          />
        </PlotTooltip>
      )}
    </Group>
  );
}
