import { Container } from '@chakra-ui/react';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureLocalStartOfMonth,
  getNextMonth,
} from '@resistapp/common/friendly';
import { flattenSamplesByUID } from '@resistapp/common/utils';
import { subHours } from 'date-fns';
import { chain, isDate } from 'lodash';
import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export function TimeFilterSelector() {
  const { queryFilters, data } = useSampleDataContext();

  const allSamplesInArray = flattenSamplesByUID(data?.samplesByUID || {});
  const uniqueDates = chain(allSamplesInArray)
    .map('time')
    .filter(isDate)
    .uniqBy(date => date.getTime())
    .sortBy(date => date.getTime())
    .value();
  const [firstDate, lastDate] = [uniqueDates[0], uniqueDates[uniqueDates.length - 1]];
  const hasMultipleYears = firstDate.getFullYear() !== lastDate.getFullYear();

  // When the date is in year 1900, it means that the month is not selected
  const selectedStartMonth =
    queryFilters.filters.interval.start.getFullYear() === DEFAULT_START_INTERVAL.getFullYear()
      ? ensureLocalStartOfMonth(firstDate)
      : ensureLocalStartOfMonth(queryFilters.filters.interval.start);
  const selectedEndMonth =
    queryFilters.filters.interval.end.getFullYear() === DEFAULT_END_INTERVAL.getFullYear()
      ? ensureLocalStartOfMonth(lastDate)
      : ensureLocalStartOfMonth(getPreviousDay(ensureLocalStartOfMonth(queryFilters.filters.interval.end)));
  const { setMonthStable } = queryFilters;

  // These control the date picker and show which dates are being selected or have been selected
  const [selectingStartDate, setSelectingStartDate] = useState<Date | null>(selectedStartMonth);
  const [selectingEndDate, setSelectingEndDate] = useState<Date | null>(selectedEndMonth);

  const onChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      const [start, end] = Array.isArray(dates) ? dates : [dates, null];

      // This doesn't happen currently, but if the user would like to clear all selections
      if (!start) {
        setMonthStable(null, null);
        return;
      } else if (!end) {
        // User selected the first part (start of the month range). Set end to null for datepicker
        setSelectingStartDate(start);
        setSelectingEndDate(null);
        return;
      }

      // User selected a range of months. Set both start and end
      setMonthStable(start, getNextMonth(end));
      setSelectingStartDate(start);
      setSelectingEndDate(end);
    },
    [setMonthStable],
  );

  return (
    <Container pl="0px" pr="0px">
      <DatePicker
        showMonthYearPicker
        selectsRange
        showIcon
        dateFormat="MMM yyyy"
        icon={faCalendar.icon}
        includeDates={uniqueDates}
        selected={selectingStartDate}
        startDate={selectingStartDate}
        endDate={selectingEndDate}
        monthsShown={hasMultipleYears ? 2 : 1}
        onChange={onChange}
      />
    </Container>
  );
}

function getPreviousDay(date: Date) {
  return subHours(date, 12);
}
