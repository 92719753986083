import { FullProject } from '@resistapp/common/types';
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { ResearchPlotData, buildResearchPlotData } from '../data-utils/plot-data/research-plot-data';
import { QueryFilters } from '../hooks/use-query-filters/use-query-filters';
import { useAssayContext } from './assay-context';
import { useSampleDataContext } from './sample-data-context';

export interface SampleSelectionState {
  ids: string[];
  removeOldSelections: boolean;
}

interface BaseResearch {
  error: Error | null;
  loading: boolean;
  queryFilters: QueryFilters;
  plotData: ResearchPlotData | null;
  sampling: FullProject | null;
  setGraphAndLegendContainerWidth: (width: number) => void;
  graphAndLegendContainerWidth: number;
  samplesBeingSelected: SampleSelectionState;
  setSamplesBeingSelected: (value: SampleSelectionState) => void;
}

interface ResearchLoading extends BaseResearch {
  loading: true;
  plotData: null;
  sampling: null;
  againstTime: false;
}

interface ResearchLoaded extends BaseResearch {
  loading: false;
  plotData: ResearchPlotData | null;
  sampling: FullProject;
  againstTime: boolean;
}

const ResearchContext = createContext<ResearchLoading | ResearchLoaded | undefined>(undefined);

export function ResearchProvider({ children }: { children: React.ReactNode }) {
  const { data: project, queryFilters, loading, error } = useSampleDataContext();
  const { allGeneGroups, getGroup, assaysLoaded } = useAssayContext();
  const [graphAndLegendContainerWidth, setGraphAndLegendContainerWidth] = useState(0);
  const [samplesBeingSelected, setSamplesBeingSelectedLocal] = useState<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  const sampleRef = useRef<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  sampleRef.current = samplesBeingSelected;

  const projectIsValid = project && validateProject(project);

  // PREPARE RESEARCH PLOT DATA
  const plotData = useMemo(() => {
    // TODO this is heavy and should be run only once on load: when filters change.
    // This would be to avoid double runing it first on project load, and then immediatelly again
    // because filters.selectedEnvironmentNamesOrdered depends on the project.
    return projectIsValid && assaysLoaded
      ? buildResearchPlotData(project, queryFilters.filters, allGeneGroups, getGroup)
      : null;
  }, [projectIsValid, assaysLoaded, project, queryFilters.filters, allGeneGroups, getGroup]);

  const setSamplesBeingSelected = (newSelection: SampleSelectionState) => {
    if (newSelection.ids.length === 0) {
      setSamplesBeingSelectedLocal({ ids: [], removeOldSelections: newSelection.removeOldSelections });
    } else {
      setSamplesBeingSelectedLocal(newSelection);
    }
  };

  if (plotData && projectIsValid && !loading && assaysLoaded) {
    const contextData = {
      loading: false as const,
      plotData,
      sampling: project,
      queryFilters,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
      againstTime: plotData.againstTime,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  } else {
    const contextData = {
      loading: true as const,
      plotData: null,
      sampling: null,
      queryFilters,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
      againstTime: false as const,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  }
}

export function useResearchContext() {
  const context = useContext(ResearchContext);

  if (!context) {
    throw new Error('useResearchContext must be used within a ResearchProvider');
  }

  return context;
}

/**
 * Validates the structure and content of the sampling data.
 * @param {FullProject | null} project - The sampling data to validate.
 * @returns {boolean} - Returns true if the sampling data is valid, otherwise false.
 */
function validateProject(project: Partial<FullProject>): project is FullProject {
  // Example validation: Ensure that the sampling has necessary properties
  const requiredProperties = ['samplesByUID', 'focusedByUID', 'qpcrFiles'];
  const hasAllProperties = requiredProperties.every(prop => prop in project);

  if (!hasAllProperties) {
    return false;
  }

  // Further validations can be added here based on project requirements
  return true;
}
