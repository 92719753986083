import { useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { getComparableGroups } from '@resistapp/client/components/shared/environments';
import { theme } from '@resistapp/client/components/shared/theme';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getBeforeOrAfterAbundances,
  OverviewDatum,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { GetGroup, L2Target } from '@resistapp/common/assays';
import { AllProjectEnvironmentTypesGroup } from '@resistapp/common/comparable-env-groups';
import { getResistanceIndexData } from '@resistapp/common/statistics/resistance-index';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';
import { DeadCenter } from '../../../../shared/layout';
import { TrendLineWithCircles } from '../../chart-components/index-line-with-circles';
import { leftLegendOffset, positioning, strokeColor } from '../chart-styles';
import { QuartileRange } from '../quartile-range';
import ReferenceLines from '../reference-lines';
import { perpareLeftAxisTick, prepareBottomAxisTick } from '../ticks';
import { TrendcharBottomLegend } from '../trendchart-bottom-legend';

interface Props {
  width: number;
  height: number;
  indexScale: ScaleLinear<number, number>;
  labelScale: ScaleLinear<number, number>;
  numTicks?: number;
  Legend: React.ComponentType<{ left: number; height: number; width: number }>;
  getValue: (d: OverviewDatum) => number | undefined;
  showData?: {
    quartileRange?: boolean;
    referenceLines?: boolean;
  };
  TooltipComponent: () => JSX.Element | null;
}

export function BaseChart(props: Props) {
  const { width, height, indexScale, labelScale, numTicks, Legend, getValue, showData, TooltipComponent } = props;

  const { getGroup } = useAssayContext();
  const { queryFilters } = useSampleDataContext();
  const {
    loading,
    trendData,
    hasPreparedData,
    selectedOrHoveredAreaOrSiteEnvId,
    selectedSiteDatum,
    activeOverviewConfiguration,
    availableEnvGroups,
    selectedEnvironmentTypeGroup,
    metricMode,
    effectiveSiteDetailsProcessMode,
  } = useOverviewContext();
  const trendLineRef = useRef<HTMLDivElement | null>(null);
  const {
    trenchartTooltip: { onMouseLeave, mouseMoveHandler, mouseClickHandler },
    graphWidth,
    timeScale,
  } = useTrendChartContext();

  const graphHeight = Math.max(0, height - positioning.margin.top - positioning.margin.bottom);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const comparableGroups = availableEnvGroups && getComparableGroups(availableEnvGroups, metricMode);
  const noDataAvailableForSite =
    !comparableGroups?.includes(selectedEnvironmentTypeGroup) &&
    selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  const correctTrendData = useMemo(
    () =>
      (noDataAvailableForSite
        ? []
        : selectedSiteDatum
          ? trendData?.filter(data => data[0].environment.id === selectedSiteDatum.environment.id)
          : trendData
      )?.map(data =>
        data.map(d =>
          // HACK: for ARGI interquartile range, recalculate stats for selected antibiotic only
          focusArgiStatsOnSelectedTarget(
            d,
            metricMode,
            effectiveSiteDetailsProcessMode,
            queryFilters.filters.selectedTargets,
            getGroup,
          ),
        ),
      ),
    [
      noDataAvailableForSite,
      selectedSiteDatum,
      trendData,
      metricMode,
      effectiveSiteDetailsProcessMode,
      getGroup,
      queryFilters.filters.selectedTargets,
    ],
  );

  const precalculatedTrendData = useMemo(() => {
    return (
      correctTrendData?.map(environmentData =>
        environmentData.map(datum => ({
          ...datum,
          precalculatedValue: getValue(datum),
        })),
      ) || []
    );
  }, [correctTrendData, getValue]);

  if (loading) {
    return <DeadCenter>Loading resistance trend data...</DeadCenter>;
  } else if (!hasPreparedData) {
    return <DeadCenter>{'Preparing resistance trend data...'}</DeadCenter>;
  }

  const selectedEnvironmentData = precalculatedTrendData.find(
    data => data[0].environment.id === selectedOrHoveredAreaOrSiteEnvId,
  );
  const xTicks = 12; // Always use 12 ticks for monthly data

  const areAllAntibioticsSelected = queryFilters.filters.selectedTargets.length === 0;

  return (
    <TrendChartContainerWithLegends ref={trendLineRef} onMouseLeave={onMouseLeave} className="base-chart">
      <LeftLegendAndChartContainer>
        <Legend left={positioning.margin.legendLeft} height={height} width={40} />
        <svg width={width} style={{ minHeight: '480px', overflow: 'visible' }}>
          <Group left={positioning.margin.left - 10} top={positioning.margin.top / 2}>
            {/* This shows the numbers on the left, not the colors */}
            <AxisLeft
              scale={labelScale}
              hideAxisLine={true}
              left={leftLegendOffset}
              tickStroke={strokeColor}
              axisClassName="trendchart-legend-label trendchart-legend-label-left"
              tickComponent={perpareLeftAxisTick(activeOverviewConfiguration)}
              numTicks={numTicks}
            />
            {!isMobile && timeScale && (
              <AxisBottom
                top={graphHeight + positioning.bottomLegend.positionY}
                scale={timeScale}
                numTicks={xTicks}
                hideAxisLine={true}
                tickStroke={strokeColor}
                axisClassName="trendchart-legend-label"
                tickFormat={value => value.valueOf().toString()}
                tickComponent={prepareBottomAxisTick(timeScale.domain()[0])}
              />
            )}
            {/* Rounded rectangle around canvas */}
            <rect
              x={0}
              y={0}
              id="canvas-border"
              width={graphWidth}
              rx={8}
              height={graphHeight}
              fill="white"
              style={{ strokeWidth: '1', stroke: strokeColor }}
            />
            {hasTrendDataToShow(precalculatedTrendData) && timeScale ? (
              <>
                {showData?.quartileRange && (
                  <QuartileRange
                    selectedEnvironmentData={selectedEnvironmentData}
                    timeScale={timeScale}
                    resistanceIndexScale={indexScale}
                    graphHeight={graphHeight}
                    mouseMoveHandler={mouseMoveHandler}
                  />
                )}
                {showData?.referenceLines && (
                  <ReferenceLines
                    width={width}
                    graphHeight={graphHeight}
                    graphWidth={graphWidth}
                    strokeColor={strokeColor}
                    margin={positioning.margin}
                  />
                )}
                {precalculatedTrendData.map((lineData, i) => (
                  <TrendLineWithCircles
                    key={i}
                    data={lineData}
                    timeScale={timeScale}
                    valueScale={d => d.precalculatedValue}
                    selected={lineData[0].environment.id === selectedOrHoveredAreaOrSiteEnvId}
                    mouseMoveHandler={mouseMoveHandler}
                    mouseClickHandler={mouseClickHandler}
                    siteSelected={!!selectedSiteDatum}
                  />
                ))}
              </>
            ) : (
              <foreignObject x="0" y="0" width={graphWidth} height={graphHeight}>
                <div style={{ height: '100%', width: '100%', backgroundColor: 'inherit' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      padding: theme.spacing[4],
                    }}
                  >
                    No trend data to show
                  </div>
                </div>
              </foreignObject>
            )}
          </Group>
        </svg>
      </LeftLegendAndChartContainer>
      <TrendcharBottomLegend
        isMobile={isMobile}
        areAllAntibioticsSelected={areAllAntibioticsSelected}
        showData={showData}
      />
      {selectedOrHoveredAreaOrSiteEnvId && hasTrendDataToShow(precalculatedTrendData) && <TooltipComponent />}
    </TrendChartContainerWithLegends>
  );
}

function hasTrendDataToShow(trendData: OverviewDatum[][]) {
  return trendData.length > 1 || trendData[0]?.length > 1;
}

export const TrendChartContainerWithLegends = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

export const LeftLegendAndChartContainer = styled.div`
  height: 100%;
`;

function focusArgiStatsOnSelectedTarget(
  d: OverviewDatum,
  metricMode: MetricMode,
  effectiveSiteDetailsProcessMode: ProcessMode,
  l2Targets: L2Target[],
  getGroup: GetGroup,
) {
  const abundances = getBeforeOrAfterAbundances(d, effectiveSiteDetailsProcessMode);
  const ret =
    !abundances?.length || l2Targets.length !== 1 || metricMode !== MetricMode.ARGI
      ? d
      : {
          ...d,
          ...getResistanceIndexData(abundances, l2Targets, getGroup),
        };
  return ret;
}
