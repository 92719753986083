import { Flex, SimpleGrid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { L2Target, L2Targets } from '@resistapp/common/assays';
import { friendlyL2Target } from '@resistapp/common/friendly';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { useTooltipInPortal } from '@visx/tooltip';
import { ReactNode } from 'react';
import { CloseIconCircle } from '../icons/close-icon';
import { OverviewMetricLabel } from '../shared/overview-metric-label';
import { theme } from '../shared/theme';
import { PlotTooltip, PlotTooltipProps, usePlotTooltip } from '../tooltips/plot-tooltip';
import { SiteDetailTitle } from './site-detail-title';
import { formatGeneMutationSuffixes } from './site-details/general-site-details';

export interface AntibioticStats {
  antibiotic: L2Target;
  metric: number | null;
  metricLevel: ResistanceLevel | null;
  detected: number;
  analysed: number;
  groupColor: string;
  groupTextColor: string;
  analysedGenes: AnalysedGeneStats[];
  reduction: number | null;
  log10: number | null;
  isDisabled?: boolean;
}

// Typecript error appears if we don't export this, since it's used in AntibioticStats
export interface AnalysedGeneStats {
  gene: string;
  copyNumber: number | null;
  copiesPerL: number | null;
  riskClass: string | undefined;
  reduction: number | null;
  log10: number | null;
}

interface Props {
  title: string;
  explanation: ReactNode;
  antibioticStats: AntibioticStats[];
}

export function SiteAntibioticDetailBox({ title, explanation, antibioticStats }: Props) {
  const { metricMode, activeOverviewConfiguration, activeChartUnit } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: true,
  });
  const { tooltipData, tooltipProps, handleMouseMove, hideTooltip } = usePlotTooltip<AntibioticStats>(tooltipStuff);
  const tooltipRef = tooltipStuff.containerRef;
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const primaryTooltipInformation = {
    label: activeOverviewConfiguration.tooltipTitle || '',
    value: (geneData: NonNullable<typeof tooltipData>['analysedGenes'][number]) =>
      activeOverviewConfiguration.getTooltipValue(geneData) || '',
  };
  const secondaryTooltipInformation = {
    label: activeOverviewConfiguration.secondaryTooltipTitle || '',
    value: (geneData: NonNullable<typeof tooltipData>['analysedGenes'][number]) =>
      activeOverviewConfiguration.getSecondaryTooltipValue(geneData) || '',
  };

  return (
    <>
      <SiteDetailTitle title={title} explanation={explanation} />
      <AntibioticPlotTooltip
        tooltipProps={tooltipProps}
        backgroundColor="white"
        tooltipData={tooltipData}
        primaryTooltipInformation={primaryTooltipInformation}
        secondaryTooltipInformation={secondaryTooltipInformation}
        hideTooltip={hideTooltip}
        isTouchDevice={isTouchDevice}
      />
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3, '2xl': 4 }} gap={3} ref={tooltipRef}>
        {antibioticStats.map(antibioticDatum => {
          const metric = antibioticDatum[activeOverviewConfiguration.dataProperty];

          return (
            <Antibioitic
              key={antibioticDatum.antibiotic}
              onClick={() => {
                queryFilters.toggleGeneGroupStable(antibioticDatum.antibiotic, true);
              }}
              hasBorder={queryFilters.filters.selectedTargets.includes(antibioticDatum.antibiotic)}
              disabled={antibioticDatum.isDisabled}
            >
              <AntibioticName>{friendlyL2Target(antibioticDatum.antibiotic as L2Targets)}</AntibioticName>
              <GeneNumbersContainer id="gene-numbers-container">
                <OverviewMetricLabel
                  id="antibiotic-detail-box-metric-label"
                  level={antibioticDatum.metricLevel}
                  metricMode={metricMode}
                  metric={metric}
                  activeChartUnit={activeChartUnit}
                  style={{ minWidth: '86px', maxWidth: '86px', height: '42px' }}
                />
                <DetectedContainer>
                  <Flex
                    gap={0.5}
                    wrap="wrap"
                    onMouseEnter={event => {
                      handleMouseMove(event, antibioticDatum, true);
                    }}
                    onMouseLeave={event => {
                      handleMouseMove(event, undefined);
                    }}
                    onMouseMove={event => {
                      handleMouseMove(event, antibioticDatum);
                    }}
                  >
                    {Array.from({ length: antibioticDatum.analysed }, (_, index) => (
                      <DetectedGeneBoxes key={index} detected={index < antibioticDatum.detected ? true : false} />
                    ))}
                  </Flex>
                  <GeneDetected>
                    {antibioticDatum.detected} / {antibioticDatum.analysed} genes detected
                  </GeneDetected>
                </DetectedContainer>
              </GeneNumbersContainer>
            </Antibioitic>
          );
        })}
      </SimpleGrid>
    </>
  );
}

interface AntibioticPlotTooltipProps {
  tooltipData: AntibioticStats | undefined;
  tooltipProps: PlotTooltipProps;
  backgroundColor: string;
  primaryTooltipInformation: {
    label: string;
    value: (geneData: AnalysedGeneStats) => string;
  };
  secondaryTooltipInformation: {
    label: string | null | undefined;
    value: (geneData: AnalysedGeneStats) => string | null | undefined;
  };
  hideTooltip: () => void;
  isTouchDevice: boolean;
}

function AntibioticPlotTooltip({
  tooltipProps,
  backgroundColor,
  tooltipData,
  primaryTooltipInformation,
  secondaryTooltipInformation,
  hideTooltip,
  isTouchDevice,
}: AntibioticPlotTooltipProps) {
  if (!tooltipData) {
    return null;
  }
  return (
    <PlotTooltip {...tooltipProps} backgroundColor={backgroundColor} style={{ pointerEvents: 'auto' }}>
      <Flex pb={3} pt={2}>
        <div style={{ textAlign: 'right', width: '170px' }}>
          <span
            style={{
              padding: theme.spacing[1],
              borderRadius: theme.borders.radius.small,
              backgroundColor: tooltipData.groupColor,
              color: tooltipData.groupTextColor,
            }}
          >
            {friendlyL2Target(tooltipData.antibiotic as L2Targets)}
          </span>
          &nbsp;&nbsp;
        </div>
        <div style={{ flexGrow: 1, textAlign: 'left', width: 'auto', fontWeight: theme.fontWeight.extraHeavy }}>
          {primaryTooltipInformation.label}
        </div>
        <div
          style={{
            flexGrow: 1,
            paddingLeft: theme.spacing[2],
            textAlign: 'left',
            width: 'auto',
          }}
        >
          {secondaryTooltipInformation.label}
        </div>
        <hr />
      </Flex>
      {tooltipData.analysedGenes.map(geneData => (
        <Flex key={geneData.gene} justify="space-between" align="center">
          <div style={{ textAlign: 'right', width: '170px' }}>{formatGeneMutationSuffixes(geneData.gene)}:&nbsp;</div>
          <div style={{ flexGrow: 1, textAlign: 'left', width: '40px', fontWeight: theme.fontWeight.extraHeavy }}>
            {primaryTooltipInformation.value(geneData)}
          </div>
          <div style={{ marginLeft: 'auto', paddingLeft: '8px', fontSize: theme.fontSize.small }}>
            {secondaryTooltipInformation.value(geneData)}
          </div>
        </Flex>
      ))}
      {isTouchDevice && (
        <CloseIconCircle
          onClick={hideTooltip}
          style={{
            zIndex: theme.zIndexes.closeIcon,
          }}
        />
      )}
    </PlotTooltip>
  );
}

function DetectedGeneBoxes({ detected }: { detected: boolean }) {
  return <Square className={detected ? 'filled' : ''} />;
}

const Square = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${theme.colors.neutral200};
  border-radius: ${theme.borders.radius.tiny};

  &.filled {
    background-color: ${theme.colors.neutral900};
  }
`;

interface AntibioiticStyleProps {
  hasBorder?: boolean;
  disabled?: boolean;
}

const Antibioitic = styled.div<AntibioiticStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spacing[2]};
  ${props => props.hasBorder && theme.addBorder(200, 'small')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
`;

const AntibioticName = styled.div`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};
`;

const GeneNumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DetectedContainer = styled(Flex)`
  flex-direction: column;
  padding-left: ${theme.spacing[2]};
  color: ${theme.colors.neutral600};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
`;
const GeneDetected = styled.div``;
