import styled from '@emotion/styled';
import { getGroupPalette } from '@resistapp/client/components/shared/palettes';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import {
  BarDatum,
  BarOrBoxPlotData,
  getBarDatumKeys,
  PlotDatum,
  ResearchPlotData,
} from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useExperimentalMetric } from '@resistapp/client/hooks/use-experimental-metric';
import { friendlyDate, friendlyMonth, type StandardDateFormat } from '@resistapp/common/friendly';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { getAbundanceStatsMax, getAbundanceStatsMin } from '@resistapp/common/statistics/abundance-stats';
import { FullProject } from '@resistapp/common/types';
import { AxisScale } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal, scaleUtc } from '@visx/scale';
import { useTooltipInPortal } from '@visx/tooltip';
import { addDays, addSeconds, differenceInDays, differenceInMonths, max, min } from 'date-fns';
import { compact, flatten, get } from 'lodash';
import { theme } from '../../shared/theme';
import {
  BarBoxOrdinalOrTimeAxisX,
  BarPlotDetectedGenesAxisY,
  BoxPlotRelativeAbundanceAxisY,
  HeatMapGenesAxisY,
} from './axes';
import { DataGroups, ExperimentalMetric } from './data-groups';
import { chartLayoutValues, getHeatmapChartOneGeneHeight, getShortestStepInSeconds } from './utils';

export type BarBoxOrHeatPlotProps = {
  width: number;
  type: 'bar' | 'box' | 'heat';
  chartId?: string;
  showSampleNumbers: boolean;
  project: FullProject;
};

export type ScaleTime = (n: Date) => number;
export type ScaleBand = (n: string) => number;

export const barPlotHeight = 330; // Height to accommodate legend items and plot content
export const boxPlotHeight = 400;

export function MultiPlot(props: BarBoxOrHeatPlotProps) {
  const { width, type, chartId, showSampleNumbers, project } = props;
  const { loading, plotData } = useResearchContext();
  const { allGeneGroups } = useAssayContext();
  const experimentalMetric = useExperimentalMetric();
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: true,
  });

  if (loading || !plotData) {
    return null;
  }

  const { againstTime, geneGroups, numAnalyzed } = plotData;

  const tooltipRef = tooltipStuff.containerRef;
  const data: PlotDatum[][] = getPlotData(type, plotData);
  const isBarShapedPlot = type !== 'box';
  const barDatumKeys = getBarDatumKeys(get(data, [0, 0], []) as BarDatum);
  const numKeys = barDatumKeys.length;
  const singleGeneHeatBoxHeight = getHeatmapChartOneGeneHeight(numKeys);
  const height = getChartHeight(type, numKeys);

  // Common: Margins and widths
  const verticalOffset = 12; // Pixels, for the upper halves of the first axis marker possible outlier dots
  const xMax = width - chartLayoutValues.plotLeftOffset; // In pixels, how many pixels from left to right the drawing area spans.

  const strDates = compact(flatten(data.map(datums => datums.map(datum => datum.date))));
  const dates = strDates.map(s => new Date(s));
  const timeDomain = againstTime ? [addDays(min(dates), -2), addDays(max(dates), 2)] : []; // Leave some slack, but not much (the time margin may have filtered samples)

  const yMax = height - verticalOffset * 2;

  // Common: time or ordinal x-axis scale
  const borderRoundingRadius = 10;
  const range: [number, number] = [borderRoundingRadius * 1.5, xMax - borderRoundingRadius * 1.5];
  const xScale: AxisScale<number> = againstTime
    ? scaleUtc({
        domain: timeDomain,
        range,
      })
    : scaleBand<string>({
        domain: data.map((_, i) => `${i}`), // groups not supported in ordinal plotting
        range,
        padding: 0.2, // Add padding between bars
      });

  // Common: x-axis formatting
  const getXAxisParameters = () => {
    if (!againstTime) {
      return {
        xTicks: data.length,
        getLabel: !showSampleNumbers ? (x: string) => data[+x][0].label : (x: string) => data[+x][0].sampleNumber,
      };
    } else {
      const numDays = differenceInDays(timeDomain[1], timeDomain[0]);
      const showDatesOnAxis = numDays <= 40 || differenceInMonths(timeDomain[1], timeDomain[0]) <= 1;
      const maxLabels = showDatesOnAxis
        ? differenceInDays(timeDomain[1], timeDomain[0])
        : differenceInMonths(timeDomain[1], timeDomain[0]);
      const labelWidth = showDatesOnAxis ? 80 : 40; // 2022 or 30. Oct
      const numLabelsThatFit = Math.floor(xMax / labelWidth); // Maximum number of labels that can fit without overlapping
      return {
        xTicks: Math.min(maxLabels, numLabelsThatFit), // Use the smaller of calculated maxLabels or maxPossibleLabels
        getLabel: (x: string) => {
          return showDatesOnAxis
            ? friendlyDate(x as StandardDateFormat, 'none')
            : friendlyMonth(x as StandardDateFormat, 'replaceJan');
        },
      };
    }
  };

  const { xTicks, getLabel } = getXAxisParameters();

  // Common: group spacing
  const shortestTimeStep = getShortestStepInSeconds(dates);
  const maxGroupWidth = 60;
  const minGroupWidth = 10;
  const groupWidth = againstTime
    ? Math.max(
        minGroupWidth,
        Math.min(maxGroupWidth, xScale(addSeconds(timeDomain[0], shortestTimeStep)) || maxGroupWidth),
      )
    : Math.max(minGroupWidth, Math.min(maxGroupWidth, xMax / data.length - minGroupWidth));

  // Common: for some reason, ordinal/linear xScale slightly misaligns bar compared to ticks without help
  const ordinalAligmentFixup =
    againstTime || !data.length
      ? 0
      : data.length === 1
        ? xMax / 3 - 10
        : 0.4 * ((xScale as ScaleBand)('1') - (xScale as ScaleBand)('0')) - 2;

  // Plot specific: y-axis and box color scale
  const extremeYValue =
    type !== 'box'
      ? Infinity
      : isConsideredAbsolute(plotData.normalisationMode)
        ? getAbundanceStatsMax(flatten(plotData.boxData))
        : getAbundanceStatsMin(flatten(plotData.boxData));

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    round: true,
    clamp: true,
    domain: isBarShapedPlot
      ? [0, numAnalyzed]
      : isConsideredAbsolute(plotData.normalisationMode)
        ? [0, Math.ceil(extremeYValue)]
        : [Math.floor(extremeYValue), 0],
  });

  // Bar plot specific:
  const palette = isBarShapedPlot ? getGroupPalette(geneGroups, plotData.grouping, allGeneGroups) : undefined;
  const colorScale = isBarShapedPlot
    ? scaleOrdinal<string, string>({
        domain: geneGroups,
        range: palette,
      })
    : undefined;

  // For some reason the heatmap need more height, than the others
  const groupHeight = yMax + (type === 'heat' ? 66 : 0);
  const showOnlyTicks = width / data.length < 17;

  return width < 10 ? null : (
    <RelativeDiv>
      <svg
        ref={tooltipRef}
        width={width}
        height={height + chartLayoutValues.xAxisHeight}
        style={{ overflow: 'visible' }}
        id={chartId}
      >
        <Group top={verticalOffset} left={chartLayoutValues.plotLeftOffset}>
          <rect
            x={0}
            y={-2}
            width={width - chartLayoutValues.plotLeftOffset}
            height={groupHeight + 2}
            fill="none"
            stroke={theme.colors.neutral400}
            rx={borderRoundingRadius}
            ry={borderRoundingRadius}
          />

          {type === 'heat' && (
            <HeatMapGenesAxisY
              colorScale={colorScale}
              grouping={plotData.grouping}
              sortIdxByGene={plotData.sortIdxByGene}
              singleGeneHeatBoxHeight={singleGeneHeatBoxHeight}
              example={plotData.heatData?.[0]?.[0]}
            />
          )}

          {type === 'box' ? (
            <BoxPlotRelativeAbundanceAxisY yScale={yScale} yMax={yMax} xMax={xMax} extremeYValue={extremeYValue} />
          ) : (
            type === 'bar' && <BarPlotDetectedGenesAxisY yScale={yScale} yMax={yMax} xMax={xMax} />
          )}
          <DataGroups
            data={data as BarOrBoxPlotData}
            tooltipStuff={tooltipStuff}
            xScale={xScale}
            yScale={yScale}
            againstTime={againstTime}
            groupWidth={groupWidth}
            ordinalAligmentFixup={ordinalAligmentFixup}
            grouping={plotData.grouping}
            barPlotGeneGroups={isBarShapedPlot ? geneGroups : undefined}
            colorScale={colorScale}
            type={type}
            sortIdxByGene={plotData.sortIdxByGene}
            singleGeneHeatBoxHeight={singleGeneHeatBoxHeight}
            showSampleNumbers={showSampleNumbers}
            project={project}
          />
        </Group>
        {type !== 'heat' && (
          <BarBoxOrdinalOrTimeAxisX
            width={width}
            height={height}
            xScale={xScale}
            getLabel={getLabel}
            againstTime={againstTime}
            numTicks={xTicks}
            showOnlyTicks={showOnlyTicks}
          />
        )}
      </svg>
      {type === 'box' && experimentalMetric ? (
        <ExperimentalMetric top={265} left={0} value={experimentalMetric} groupWidth={40} />
      ) : null}
    </RelativeDiv>
  );
}

export function getChartHeight(type: string, numKeys: number) {
  return type === 'heat' ? (numKeys > 100 ? 5 : 10) * numKeys : type === 'bar' ? barPlotHeight : boxPlotHeight;
}

const RelativeDiv = styled.div`
  position: relative;
`;

/**
 * Function to retrieve plot data based on the type of plot.
 * @param {string} type - The type of plot data to retrieve ('bar', 'box', or 'heat').
 * @returns {Array} - The corresponding plot data array.
 */
const getPlotData = (type: string, plotData: ResearchPlotData) => {
  switch (type) {
    case 'bar':
      return plotData.barData || [];
    case 'box':
      return plotData.boxData || [];
    case 'heat':
      return plotData.heatData || [];
    default:
      return [];
  }
};
