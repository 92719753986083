import { GetGroup, L1Target, L2Target } from '@resistapp/common/assays';
import { filterMinorTarget, isMinorTarget, MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { PartialAbundance, WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { filterDetected } from '@resistapp/common/utils';
import { chain, isNil, keys, mean } from 'lodash';
import { getBeforeOrAfterAbundances } from './build-overview-line-data';

export interface DetectedAndAnalysedCounts {
  detectedCount: number;
  analysedCount: number;
}

export function getNumDetectedAndAnalysedAssays(
  overviewDatum: WithAbundances,
  target: L1Target | L2Target | MinorTarget,
  processMode: ProcessMode,
  getGroup: GetGroup,
): DetectedAndAnalysedCounts {
  const abundances = getBeforeOrAfterAbundances(overviewDatum, processMode);
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target, getGroup) : {};
  const analysedCount = keys(targetedAbundancesByAssay).length;
  const detectedCount = chain(targetedAbundancesByAssay)
    .mapValues(filterDetected)
    .pickBy(abs => abs.length)
    .keys()
    .value().length;
  return { detectedCount, analysedCount };
}

export function getAnalysedAssays(
  overviewDatum: WithAbundances,
  target: L1Target | L2Target | MinorTarget,
  processMode: ProcessMode,
  getGroup: GetGroup,
) {
  const abundances = getBeforeOrAfterAbundances(overviewDatum, processMode);
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target, getGroup) : {};
  return chain(targetedAbundancesByAssay).values().flatten().value();
}

export function targetAbundancesByAssay<T extends PartialAbundance>(
  abundances: T[],
  target: L1Target | L2Target | MinorTarget,
  getGroup: GetGroup,
  includeAY1: boolean = false,
): Record<string, T[]> {
  if (isMinorTarget(target)) {
    return chain(filterMinorTarget(abundances, target, includeAY1))
      .groupBy(d => d.assay)
      .value();
  } else {
    return chain(abundances)
      .filter(
        abundance =>
          getGroup(abundance.assay, 'l2Target') === target ||
          getGroup(abundance.assay, 'l1Target') === target ||
          (includeAY1 && abundance.assay === 'AY1'),
      )
      .groupBy(d => d.assay)
      .value();
  }
}

export function hasAbundanceByAssay<T extends PartialAbundance>(
  abundances: T[],
  target: L1Target | L2Target | MinorTarget,
  getGroup: GetGroup,
  includeAY1: boolean = false,
): boolean {
  if (isMinorTarget(target)) {
    return chain(abundances)
      .some(abundance => isMinorTarget(abundance.assay) && abundance.assay === target)
      .value();
  } else {
    return chain(abundances)
      .some(
        abundance =>
          getGroup(abundance.assay, 'l2Target') === target ||
          getGroup(abundance.assay, 'l1Target') === target ||
          (includeAY1 && abundance.assay === 'AY1'),
      )
      .value();
  }
}

export function hasAntibioticTarget(
  overviewDatum: WithAbundances,
  target: L1Target | L2Target | MinorTarget,
  processMode: ProcessMode,
  getGroup: GetGroup,
): boolean {
  const abundances = getBeforeOrAfterAbundances(overviewDatum, processMode);
  return abundances ? hasAbundanceByAssay(abundances, target, getGroup) : false;
}

export interface GeneAndCopyNumber {
  assay: string;
  gene: string;
  copyNumber: number;
  copiesPerL?: number;
  reduction?: number | null;
  log10?: number | null;
  l2Target: L2Target;
}

export function getGenesAndCopyNumbers(
  overviewDatum: WithAbundances,
  target: L1Target | L2Target | MinorTarget,
  metricMode: MetricMode,
  processMode: ProcessMode,
  getGroup: GetGroup,
  includeUndetected: boolean = false,
): GeneAndCopyNumber[] {
  const considerSecondaryDetections = metricMode === MetricMode.REDUCTION && processMode === ProcessMode.DURING;
  const abundances = getBeforeOrAfterAbundances(overviewDatum, processMode);
  const detectedPrimaryAssays = new Set(abundances?.filter(a => !isNil(a.absolute)).map(a => a.assay));
  const secondaryAbundances = considerSecondaryDetections
    ? getBeforeOrAfterAbundances(overviewDatum, ProcessMode.AFTER) || []
    : [];
  const detectedOnlyInSecondaryAssays = considerSecondaryDetections
    ? new Set(
        secondaryAbundances.filter(a => !isNil(a.absolute) && !detectedPrimaryAssays.has(a.assay)).map(a => a.assay),
      )
    : new Set();
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target, getGroup) : {};
  return chain(targetedAbundancesByAssay)
    .pickBy(
      (_, assay) => includeUndetected || detectedPrimaryAssays.has(assay) || detectedOnlyInSecondaryAssays.has(assay),
    )
    .mapValues((assayAbundances, assay) => {
      const copyNumber = mean(assayAbundances.map(a => a.absolute));
      const copiesPerL = mean(assayAbundances.map(a => a.copiesPerL));
      return {
        assay,
        gene: assayAbundances[0].gene,
        l2Target: getGroup(assay, 'l2Target') as L2Target,
        // TODO add both before and after copy number here
        // show based on process mode in the UI eiter before, after, or both
        // See eg. 1978 > Reduction > Blomminmäki > August > Pathodens > P.aeruginosa
        copyNumber: isNaN(copyNumber) ? 0 : copyNumber,
        copiesPerL: isNaN(copiesPerL) ? 0 : copiesPerL,
      };
    })
    .values()
    .value();
}
