import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSelectedSiteDatumOrThrow } from '@resistapp/client/contexts/use-overview-context/overview-context-utils';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import {
  getAnalysedAssays,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { getMetricAndLevel } from '@resistapp/client/utils/metric-utils';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { antibioticL2Targets, AssayInfo, GeneGroups, GetGroup, L2Target } from '@resistapp/common/assays';
import { riskClassByOneHealthAssay } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { get10FoldChangeInVolume, getRelative10FoldChange } from '@resistapp/common/statistics/fold-change';
import { ChartUnit, MetricMode, ProcessMode } from '@resistapp/common/types';
import { MetricTitle } from '../../shared/overview-metric-title';
import { getGroupColor, getGroupTextColor } from '../../shared/palettes';
import { theme } from '../../shared/theme';
import { AnalysedGeneStats, AntibioticStats, SiteAntibioticDetailBox } from '../site-antibiotic-detail-box';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';

export function AntibioticCompositionDetails(props: SiteDetailsBaseProps) {
  const { metricMode, effectiveSiteDetailsProcessMode, activeChartUnit } = useOverviewContext();
  const { allGeneGroups, allAssays, getGroup } = useAssayContext();
  const selectedSiteDatum = useSelectedSiteDatumOrThrow();
  const { containerRef, antibioticTarget } = props;

  let errorMessage: string | undefined;
  const antibioticStats: AntibioticStats[] = antibioticL2Targets
    .map(antibiotic => {
      try {
        return createAntibioticGeneStats(
          selectedSiteDatum,
          antibiotic,
          metricMode,
          effectiveSiteDetailsProcessMode,
          activeChartUnit,
          allGeneGroups,
          allAssays,
          getGroup,
        );
      } catch (err) {
        console.error('Error creating antibiotic stats', err);
        errorMessage = err instanceof Error ? err.message : 'Unknown error';
        return {} as unknown as AntibioticStats;
      }
    })
    // Only show antibiotics with analysed genes
    .filter(antibiotic => antibiotic.analysed > 0)
    // mark disabled antibiotics
    .map(antibiotic => ({
      ...antibiotic,
      isDisabled: (antibioticTarget && !antibioticTarget.includes(antibiotic.antibiotic)) || false,
    }))
    // Alphabetical order
    .sort((a, b) => (a.antibiotic < b.antibiotic ? -1 : 1));

  const title = `${metricMode === MetricMode.REDUCTION ? 'Changes in a' : 'A'}ntibiotic resistance composition`;
  const explanation = (
    <span>
      Target antibiotic group specific
      <MetricTitle noAntibiotic={true} plural={true} />
      {get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode)}
    </span>
  );

  if (errorMessage) {
    return (
      <SiteDetailContainer
        gridColumn={{ base: 1, lg: 'span 2' }}
        ref={containerRef}
        key="site-detail-box-arg"
        style={{ padding: theme.spacing[6] }}
      >
        <SiteDetailTitle title={title} explanation={explanation} />
        <div>
          We could not calculate the antibiotic resistance composition for this site.
          <br />
          Reason: <b>{errorMessage}</b>
        </div>
      </SiteDetailContainer>
    );
  }

  return (
    <SiteDetailContainer gridColumn={{ base: 1, lg: 'span 2' }} ref={containerRef} key="site-detail-box-arg">
      <SiteAntibioticDetailBox title={title} explanation={explanation} antibioticStats={antibioticStats} />
    </SiteDetailContainer>
  );
}

function createAntibioticGeneStats(
  selectedSiteDatum: OverviewDatum,
  antibiotic: L2Target,
  metricMode: MetricMode,
  processMode: ProcessMode,
  activeChartUnit: ChartUnit,
  allGeneGroups: GeneGroups,
  allAssays: AssayInfo[],
  getGroup: GetGroup,
): AntibioticStats {
  const counts = getNumDetectedAndAnalysedAssays(selectedSiteDatum, antibiotic, processMode, getGroup);
  const [metric, metricLevel] = getMetricAndLevel(
    selectedSiteDatum,
    [antibiotic],
    metricMode,
    processMode,
    activeChartUnit,
    getGroup,
    allAssays,
  );
  const sortByField = metricMode === MetricMode.REDUCTION ? 'reduction' : 'copyNumber';
  const sortOrder = metricMode === MetricMode.REDUCTION ? 1 : -1;
  const groupColor = getGroupColor(antibiotic, 'antibiotic', allGeneGroups);
  const groupTextColor = getGroupTextColor(antibiotic, 'antibiotic', allGeneGroups);
  const analysedGenes = getAnalysedAssays(selectedSiteDatum, antibiotic, processMode, getGroup)
    .map(assay => {
      const geneReduction = getRelative10FoldChange(
        selectedSiteDatum.beforeAbundances,
        selectedSiteDatum.afterAbundances,
        assay.assay,
        allAssays,
      );
      const geneReduction10 = get10FoldChangeInVolume(
        selectedSiteDatum.beforeAbundances,
        selectedSiteDatum.afterAbundances,
        assay.assay,
        allAssays,
      );

      return {
        gene: assay.gene,
        copyNumber: assay.absolute,
        copiesPerL: assay.absolute,
        riskClass: riskClassByOneHealthAssay[assay.assay],
        reduction: geneReduction,
        log10: geneReduction10,
      } satisfies AnalysedGeneStats;
    })
    .sort((a, b) => ((a[sortByField] || 0) > (b[sortByField] || 0) ? sortOrder : -sortOrder));
  const reduction = getRelative10FoldChange(
    selectedSiteDatum.beforeAbundances,
    selectedSiteDatum.afterAbundances,
    antibiotic,
    allAssays,
  );
  const log10 = get10FoldChangeInVolume(
    selectedSiteDatum.beforeAbundances,
    selectedSiteDatum.afterAbundances,
    antibiotic,
    allAssays,
  );
  return {
    antibiotic,
    metric,
    metricLevel,
    detected: counts.detectedCount,
    analysed: counts.analysedCount,
    reduction,
    log10,
    groupColor,
    groupTextColor,
    analysedGenes,
  };
}
