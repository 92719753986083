import { positioning } from '@resistapp/client/components/plots/trendchart/chart/chart-styles';
import { getTimeScale } from '@resistapp/client/components/plots/trendchart/chart/scales';
import { useTrendchartTooltip } from '@resistapp/client/components/plots/trendchart/chart/use-trendchart-tooltip';
import type { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import type { Continent } from '@resistapp/common/types';
import { getCountriesFromSamples } from '@resistapp/common/utils';
import type { ScaleTime } from 'd3-scale';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCountries } from '../../hooks/api';
import { getCountryAdminLevelIntersection } from './overview-context-utils';
import { useOverviewContext } from './use-overview-context';

interface TrendChartContextData {
  continent: Continent | undefined;
  trenchartTooltip: {
    trendChartSize: { width: number; height: number };
    mouseMoveHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => void;
    mouseClickHandler: (data: OverviewDatum) => void;
    onMouseLeave: () => void;
    TooltipComponentForARGIMarker: () => JSX.Element | null;
    TooltipComponentForRiskScore: () => JSX.Element | null;
    TooltipComponentForReduction: () => JSX.Element | null;
    hideTooltip: () => void;
  };
  graphHeight: number;
  graphWidth: number;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  timeScale: ScaleTime<number | undefined, number | undefined> | undefined;
}

const TrendChartContext = createContext<TrendChartContextData | undefined>(undefined);

interface TrendChartProviderProps {
  children: React.ReactNode;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export function TrendChartProvider({ children, containerRef }: TrendChartProviderProps) {
  const { shownAdminLevel, supportedSamples, selectedCountry, selectedSiteDatum, zoomedAdminAreaRef, trendData } =
    useOverviewContext();
  const { data: countryByAlpha3 } = useCountries();
  const [trendChartSize, setTrendChartSize] = useState({ width: 0, height: 0 });

  const graphWidth = useMemo(() => {
    return trendChartSize.width - positioning.margin.left - positioning.margin.right + 1;
  }, [trendChartSize.width]);

  const timeScale = useMemo(() => {
    return getTimeScale(trendData, graphWidth);
  }, [trendData, graphWidth]);

  const {
    mouseMoveHandler,
    mouseClickHandler,
    onMouseLeave,
    TooltipComponentForARGIMarker,
    TooltipComponentForRiskScore,
    TooltipComponentForReduction,
    hideTooltip,
  } = useTrendchartTooltip(timeScale, shownAdminLevel, containerRef);

  const previousSelectedSiteDatumRef = useRef<OverviewDatum | undefined>(undefined);

  // Update continent when selected country or supported samples change. This is needed for continent specific ARGI mean values.
  const continent = useMemo<Continent | undefined>(() => {
    const countries = supportedSamples && getCountriesFromSamples(supportedSamples);
    const { country } =
      shownAdminLevel !== 2 && countries?.length === 1 && supportedSamples
        ? getCountryAdminLevelIntersection(supportedSamples)
        : { country: selectedCountry };
    const newContinent = country ? countryByAlpha3?.[country]?.continent : undefined;
    return newContinent;
  }, [countryByAlpha3, selectedCountry, supportedSamples, shownAdminLevel]);

  // We setup a resize observer to update the trend chart size when the container size changes. Otherwise when the browser
  // size changes for any reason (for example site data-grids size changes), the chart parts will be misaligned.
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setTrendChartSize({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    });

    containerRef.current && resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  // Hide tooltip when zooming into single site admin area
  useEffect(() => {
    if ((selectedSiteDatum && !previousSelectedSiteDatumRef.current) || zoomedAdminAreaRef.current === null) {
      hideTooltip();
    }
    previousSelectedSiteDatumRef.current = selectedSiteDatum;
  }, [selectedSiteDatum, hideTooltip]);

  const contextValue: TrendChartContextData = {
    continent,
    trenchartTooltip: {
      trendChartSize,
      mouseMoveHandler,
      mouseClickHandler,
      onMouseLeave,
      TooltipComponentForARGIMarker,
      TooltipComponentForRiskScore,
      TooltipComponentForReduction,
      hideTooltip,
    },
    graphHeight: trendChartSize.height - positioning.margin.top - positioning.margin.bottom,
    graphWidth,
    containerRef,
    timeScale,
  };

  return <TrendChartContext.Provider value={contextValue}>{children}</TrendChartContext.Provider>;
}

export function useTrendChartContext() {
  const context = useContext(TrendChartContext);

  if (!context) {
    throw new Error('useTrendChartContext must be used within a TrendChartProvider');
  }

  return context;
}
