import { Flex } from '@chakra-ui/react';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { GeneGroups, L2Targets } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { friendlyCopyNumber, friendlyFoldChange, friendlyL2Target } from '@resistapp/common/friendly';
import { values } from 'lodash';
import { getGroupColor, getGroupTextColor } from '../../../shared/palettes';
import { theme } from '../../../shared/theme';
import { friendlyGeneName } from '../site-details-utils';
import { SingleBarDatum } from './types';

interface L2TargetTooltipContentProps {
  tooltipData: SingleBarDatum;
  activeOverviewConfiguration: OverviewChartConfiguration;
  allGeneGroups: GeneGroups;
}

export function L2TargetTooltipContent({
  tooltipData,
  activeOverviewConfiguration,
  allGeneGroups,
}: L2TargetTooltipContentProps) {
  const isL2Target = (values(L2Targets) as string[]).includes(tooltipData.barIdentifier);
  const sortedGenes = [...tooltipData.analysedGenes].sort(
    (a, b) => (b.beforeCopyNumber || 0) - (a.beforeCopyNumber || 0),
  );
  return (
    <div style={{ padding: theme.spacing[2] }}>
      <Flex pb={3} pt={2}>
        <div style={{ textAlign: 'right', width: '140px' }}>
          <span
            style={{
              padding: theme.spacing[1],
              borderRadius: theme.borders.radius.small,
              backgroundColor: getGroupColor(tooltipData.barIdentifier, 'antibiotic', allGeneGroups),
              color: getGroupTextColor(tooltipData.barIdentifier, 'antibiotic', allGeneGroups),
            }}
          >
            {isL2Target ? friendlyL2Target(tooltipData.barIdentifier as L2Targets) : ''}
          </span>
        </div>
        <div style={{ width: '120px', textAlign: 'right', paddingRight: '8px', whiteSpace: 'nowrap' }}>
          Treated copies
        </div>
        <div
          style={{
            width: '100px',
            textAlign: 'center',
            fontWeight: theme.fontWeight.extraHeavy,
            whiteSpace: 'nowrap',
          }}
        >
          {activeOverviewConfiguration.tooltipTitle}
        </div>
        <div style={{ width: '100px', textAlign: 'left', paddingLeft: '8px' }}>Raw copies</div>
      </Flex>
      {sortedGenes.map(gene => (
        <div
          key={gene.gene}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '140px',
              fontStyle: tooltipData.groupingTarget === MinorTarget.PATHOGEN ? 'italic' : 'normal',
            }}
          >
            {friendlyGeneName(gene.assay, gene.gene)}
          </div>
          <div style={{ width: '120px', textAlign: 'right', paddingRight: '8px' }}>
            {friendlyCopyNumber(gene.afterCopyNumber)}
          </div>
          <div style={{ width: '100px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>⭠</span>
            <span style={{ fontWeight: theme.fontWeight.extraHeavy }}>
              {gene.beforeCopyNumber || gene.afterCopyNumber ? friendlyFoldChange(gene.reduction) : '-'}
            </span>
            <span>⭠</span>
          </div>
          <div style={{ width: '100px', textAlign: 'left', paddingLeft: '8px' }}>
            {friendlyCopyNumber(gene.beforeCopyNumber)}
          </div>
        </div>
      ))}
    </div>
  );
}
