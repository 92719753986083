import type { OverviewDatum, OverviewLineData } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';

import { hasAntibioticTarget } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import type { GetGroup, L2Target } from '@resistapp/common/assays';
import { antibioticL2Targets } from '@resistapp/common/assays';
import { AllProjectEnvironmentTypesGroup, EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { calculateNormalizationFactors } from '@resistapp/common/normalisation-utils';
import { getAdminAreaKey } from '@resistapp/common/pool-samples';
import { AdminArea, AdminLevelKey, FullSample, NormalisationMode, ProcessMode } from '@resistapp/common/types';
import { chain, intersection, isNil, keys, type Dictionary } from 'lodash';
import type { LngLatBoundsLike, LngLatLike, MapboxMap } from 'react-map-gl';
import { useOverviewContext } from './use-overview-context';

export function getCountryAdminLevelIntersection(flatSamples: FullSample[]) {
  // TODO: should we include the admin level intersection in this function (?)
  // TODO: support samples accross countries, and with mixed admin levels available
  const countries = chain(flatSamples)
    .map(s => s.country)
    .uniq()
    .value();

  // Ensure that we only use admin levels that are available for all samples
  // Rural samples might be lacking deeper admin levels that are present in big cities.
  // Eg. sample 36381 (-7.9734244,112.6308338) has levels 1-5 whereas sample 36369 (-6.1982399,106.8451211) has levels 1-9.

  const adminLevels =
    countries.length !== 1
      ? undefined
      : chain(flatSamples)
          .map(s => s.adminLevels)
          .map(levels => keys(levels || {}))
          .reduce((previous, current) => intersection(previous, current))
          .map(level => +level)
          .value();

  const country = countries.length === 1 ? countries[0] : undefined;
  return { country, adminLevels };
}

export const GLOBAL_NAME = 'Global';
const GLOBAL_ENVIRONMENT_ID = -99999999;
export function getGlobalOverviewDatum(): OverviewDatum {
  return {
    environment: { type: EnvironmentType.OTHER, name: GLOBAL_NAME, id: GLOBAL_ENVIRONMENT_ID },
    adminLevels: { 1: { name: GLOBAL_NAME, level: 1 } },
    lat: undefined,
    lon: undefined,
    inferredLat: undefined,
    inferredLon: undefined,
    beforeAbundances: [],
    afterAbundances: [],
    country: undefined,
    region: undefined,
    city: undefined,
    date: '',
    label: '',
  };
}

export function determineZoomableLevels(
  mapDataByLevel: (Dictionary<OverviewDatum[]> & { null?: OverviewDatum[] }) | undefined,
  selectedCountry?: string,
): number[] {
  if (!mapDataByLevel) {
    return [];
  }
  if (!mapDataByLevel['null']) {
    throw new Error('Expected null level to exist');
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (mapDataByLevel['1'] && mapDataByLevel['1'].length > 1) {
    throw new Error('Expected maximum only one site on level 1');
  }

  const numSites = mapDataByLevel['null'].length;

  // First filter data by country if one is selected
  const filteredByCountry = chain(mapDataByLevel)
    .mapValues(levelAreas =>
      selectedCountry ? levelAreas.filter(area => area.country === selectedCountry) : levelAreas,
    )
    .value();

  const ret = chain(filteredByCountry)
    .toPairs()
    // Consider non-aggregated site level as the deepest level
    .sortBy(([level]) => (isNil(level) ? 16 : +level))
    .filter(([_, levelAreas]) => levelAreas.length > 0)
    .filter(
      // Get rid of weird corner cases where a level has less areas than its upper level
      ([_, levelAreas], pairIndex, allPairs) => !pairIndex || levelAreas.length >= allPairs[pairIndex - 1][1].length,
    )
    .filter(([adminLevel, levelAreas], pairIndex, allPairs) => {
      // Skip useless levels where the next level doesn't have any more areas (note: expects that null level still exists)
      // Exception with countries, which we handle with detecting is adminLevel 2
      return (
        (!selectedCountry && adminLevel === '2' && levelAreas.length > 1) ||
        (pairIndex < allPairs.length - 1 && allPairs[pairIndex + 1][1].length > levelAreas.length)
      );
    })
    // Ensure that some area on each level has more than one data point
    // Also drops special case null site level as the last operation
    .filter(([_, levelAreas]) => levelAreas.length < numSites)
    .map(([level]) => +level)
    .value();

  return ret;
}

export function useSelectedSiteDatumOrThrow() {
  const { selectedSiteDatum } = useOverviewContext();
  if (!selectedSiteDatum) {
    throw new Error('Expected site view to be active');
  }
  return selectedSiteDatum;
}

export function getAllSitesInAdminArea(adminArea: AdminArea, mapData: OverviewDatum[] | undefined) {
  return mapData?.filter(datum => {
    const key = String(adminArea.level) as AdminLevelKey;
    const localAdminArea = datum.adminLevels?.[key];
    return localAdminArea && getAdminAreaKey(localAdminArea) === getAdminAreaKey(adminArea);
  });
}

export function determineAvailableNormalisationModes(
  samples: FullSample[] | undefined,
  _envIds?: number[],
): NormalisationMode[] {
  const envIdsSet = _envIds ? new Set(_envIds) : undefined;
  const relevantSamples = !envIdsSet ? samples : samples?.filter(s => envIdsSet.has(s.environment.id));
  if (!relevantSamples) {
    return [NormalisationMode.TEN_UL_DILUTED_DNA, NormalisationMode.SIXTEEN_S];
  }
  const hasVolume = relevantSamples.every(s => !isNil(calculateNormalizationFactors(s).volumeNormalisationFactor));
  const hasTime = relevantSamples.every(s => !isNil(calculateNormalizationFactors(s).flowNormlisationFactor));
  const hasSS = relevantSamples.every(s => !isNil(calculateNormalizationFactors(s).suspendedSolidsNormlisationFactor));
  const hasBOD = relevantSamples.every(s => !isNil(calculateNormalizationFactors(s).bodNormlisationFactor));

  // Note: priority order
  return chain([
    hasVolume && NormalisationMode.LITRE,
    hasSS && NormalisationMode.MG_SS,
    hasTime && NormalisationMode.HOUR,
    NormalisationMode.TEN_UL_DILUTED_DNA,
    NormalisationMode.SIXTEEN_S,
    hasBOD && NormalisationMode.MG_BOD,
  ])
    .filter(Boolean)
    .value() as NormalisationMode[];
}

export function zoomAndCenterMap(
  mapInstance: MapboxMap,
  zoomOrBounds: { zoom: number; center: LngLatLike } | { bounds: LngLatBoundsLike },
) {
  const MIN_ZOOM = 13;
  if ('zoom' in zoomOrBounds) {
    mapInstance.flyTo({
      zoom: Math.min(MIN_ZOOM, zoomOrBounds.zoom),
      center: zoomOrBounds.center,
      padding: 120,
    });
  } else if ('bounds' in zoomOrBounds) {
    const cameraForBounds = mapInstance.cameraForBounds(zoomOrBounds.bounds);
    mapInstance.flyTo({
      zoom: Math.min(MIN_ZOOM, cameraForBounds?.zoom || MIN_ZOOM),
      center: cameraForBounds?.center,
      padding: 120,
    });
  }
}

export function getSelectedSite({
  queryFilters,
  mapData,
  supportedSamples,
}: {
  queryFilters: { filters: { selectedEnvironmentNamesOrdered: string[] } };
  mapData: OverviewDatum[] | undefined;
  supportedSamples: FullSample[] | undefined;
}) {
  const uniqSelectedLocations = chain(supportedSamples)
    .filter(s => queryFilters.filters.selectedEnvironmentNamesOrdered.includes(s.environment.name))
    .map(s => `${s.lat},${s.lon}`)
    .uniq()
    .value();

  // There is only one site (selected 1 or 2 envs, we don't support more env selections currently)
  const singleSiteSelected =
    queryFilters.filters.selectedEnvironmentNamesOrdered.length === 1 ||
    (queryFilters.filters.selectedEnvironmentNamesOrdered.length === 2 && uniqSelectedLocations.length === 1);

  const selectedEnvNames = singleSiteSelected ? queryFilters.filters.selectedEnvironmentNamesOrdered : [];

  return selectedEnvNames.length
    ? mapData?.filter(d =>
        selectedEnvNames.some(
          envName =>
            'originalEnvironmentNames' in d.environment && d.environment.originalEnvironmentNames?.includes(envName),
        ),
      )[0]
    : undefined;
}

export function getAnalysedGroupsAndAll(
  trendData: OverviewLineData | undefined,
  processMode: ProcessMode,
  getGroup: GetGroup,
) {
  const datum = trendData?.flat().find(d => !!(d.beforeAbundances || d.afterAbundances)?.length);
  const analyzedAntibioticGroups = antibioticL2Targets.filter(antibiotic => {
    // This needs to only calculate the analysed antibiotics for one data, as the data should be unified so that the
    // first one has the same as the same antibiotics as the other datas.
    return datum && hasAntibioticTarget(datum, antibiotic, processMode, getGroup);
  });
  return analyzedAntibioticGroups.length > 0
    ? (['All antibiotics', ...analyzedAntibioticGroups] satisfies Array<L2Target | 'All antibiotics'>)
    : ([] satisfies Array<L2Target | 'All antibiotics'>);
}

/**
 * If we're in ALL_PROJECT_ENVIRONMENTS and the active mode metrics aren't available, use the other mode's metrics
 */
export function getEffectiveProcessMode(
  overviewDatum: OverviewDatum | undefined,
  processMode: ProcessMode,
  selectedEnvironmentTypeGroup: EnvironmentTypeGroup | null,
): ProcessMode {
  return !overviewDatum
    ? processMode
    : selectedEnvironmentTypeGroup === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
      ? processMode === ProcessMode.AFTER &&
        !overviewDatum.afterAbundances?.length &&
        overviewDatum.beforeAbundances?.length
        ? ProcessMode.BEFORE
        : processMode === ProcessMode.BEFORE &&
            !overviewDatum.beforeAbundances?.length &&
            overviewDatum.afterAbundances?.length
          ? ProcessMode.AFTER
          : processMode
      : processMode;
}
